<template>
  <div class="report-view skill-report pb-5">

    <div class="viewEdit ttrInfo">

      <!-- Heading  -->
      <b-card class="d-flex">
        <div class="calendar justify-content-between mt-0 custom-width">

          <p class="task-timesheet">
            Detailed Project Health Report 
          </p>
        </div>
      </b-card>

      <!--filter-->
      <div class="card-filter-section">
        <div class="d-flex align-items-center flex-wrap justify-content-between">
          <div class="d-flex align-items-center justify-content-start flex-wrap">

            <div class="d-flex align-items-center">
              <button
                class="back-button btn btn-secondary mr-2"
                @click="back()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  width="18"
                  height="18"
                />
                <span>Back</span>
              </button>
              <p class="filter-title">
                Filters:
              </p>
            </div>

            <div>
              <custom-dropdown
                v-model="project_id"
                :options="projectLists"
                :label="projectName"
                hide-close
                hide-count
              />
            </div>

          </div>

          <div
            v-show="false"
            class="header-project-name"
          >
            {{ projectName }}
          </div>

          <div class="card-header-second mt-0">
            <div class="button-group">
              <b-button-group>
                <b-button
                  :class="[
                    tab === 'listing'
                      ? 'button-group-item active'
                      : 'button-group-item',
                  ]"
                  @click="tab = 'listing'"
                >List </b-button>
                <b-button
                  :class="[
                    tab === 'chart'
                      ? 'button-group-item-weekly active'
                      : 'button-group-item-weekly',
                  ]"
                  @click="tab = 'chart'"
                >Graph </b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--chart view -->
    <div v-if="tab == 'chart'">
      <progress-shimmer v-if="reportLoader" />
      <!--  -->
      <div
        v-else
        class="total-project-hour-dashboard hrinoutinfo autoDayout mxHeight"
        style="height:680px"
      >
        <div class="d-flex align-items-center justify-content-between hrFlatpicker">
          <div>
            <h3
              class="mb-0 total-hours-time"
            >
              <span>{{ projectName }} </span>
            </h3>

          </div>
        </div>
        <div
          class="all-project-progress hr-dashboard"
          style="min-height:90%"
          :class="{ 'overflow-hidden': !series[0].data.length }"
        >
          <apexchart
            v-show="(series[0].data.length)"
            ref="chart"
            type="line"
            height="580"
            :options="chartOptions"
            :series="series"
          />

          <div
            v-show="!(series[0].data.length)"
            class="overflow-hidden"
          >
            <img
              src="@/assets/images/nodatafound/nodata.svg"
              alt="no-data"
              class="no-data-img no-data-dashboard"
            >
          </div>

        </div>
      </div>
    </div>

    <!-- list view-->
    <div v-else>
      <!-- loader -->
      <div v-if="reportLoader">
        <time-sheet-loader :row-num="3" />
      </div>

      <!-- Report List -->
      <div
        v-else-if="reportList && reportList.length > 0"
        class="project-health-report-list"
      >
        <b-row
          v-for="(report, index) in reportList"
          :key="index"
          class="w-100 p-1"
        >
          <validation-observer
            :ref="`clientObserver${report.id}`"
            v-slot="{ validate }"
          >
            <table class="report-table ml-1">
              <thead>
                <tr>
                  <th
                    colspan="5"
                    class="header-first-row p-1"
                  >
                    {{ reportMonthFormat(`${report.month}-${report.year}`) }}
                  </th>
                  <th
                    class="header-first-row-button "
                    :class="{
                      'is_reviewed': report.is_reviewed,
                      'pending-to-review': !report.isEditing && !report.is_reviewed
                    }"
                    colspan="2"
                    @click="!report.isEditing && !report.is_reviewed ? toggleEdit(index) : report.isEditing && !report.is_reviewed ? handleSubmit(index, validate) : null"
                  >
                    <button
                      v-if="!report.isEditing && !report.is_reviewed"
                      class=" btn-edit header-first-row-button pending-to-review"
                    >
                      Review Pending
                    </button>
                    <button
                      v-if="report.isEditing && !report.is_reviewed"
                      class=" btn-save header-first-row-button "
                    >
                      Save Changes & Freeze
                    </button>
                    <div
                      v-if="report.is_reviewed"
                      class=" header-first-row-button is_reviewed"
                    >
                      Saved </div>

                  </th>

                </tr>
                <tr class="header-second-row">
                  <th
                    colspan="2"
                    class="p-1"
                  >
                    Project Health
                  </th>
                  <th>
                    <div v-if="report.isEditing">
                      <validation-provider
                        #default="{ errors }"
                        name="Project Health"
                        rules="required"
                      >
                        <v-select
                          id="project-health-rating"
                          v-model="report.project_health"
                          hide-details
                          name="project_health"
                          label="name"
                          :options="projectHealthRating"
                          :reduce="(projectHealthRating) => projectHealthRating.value"
                          :class="{ 'select-size-lg': true, 'vibrate-on-error mt-1': errors.length > 0 }"
                          placeholder="Select Rating"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div
                      v-else
                      class="input-display"
                    >
                      {{ report.project_health_label || '-' }}
                    </div>
                  </th>
                  <th>Client Satisfaction</th>
                  <th>
                    <div v-if="report.isEditing">
                      <validation-provider
                        #default="{ errors }"
                        name="Client Satisfaction"
                        rules="required"
                      >
                        <v-select
                          id="country name"
                          v-model="report.client_satisfaction"
                          hide-details
                          name="country"
                          label="value"
                          :options="clientSatisfaction"
                          :reduce="(clientSatisfaction) => clientSatisfaction.value"
                          :clearable="false"
                          :class="{ 'select-size-lg': true, 'vibrate-on-error mt-1': errors.length > 0 }"
                          placeholder="Select Rating"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div
                      v-else
                      class="input-display"
                    >
                      {{ report.client_satisfaction || '-' }}
                    </div>
                  </th>
                  <th>Actual Satisfaction</th>
                  <th>
                    <validation-provider
                      name="On-paper Satisfaction"
                      rules="required"
                    >
                      <div class="input-display">
                        {{ report.on_paper_satisfaction || 0 }}
                      </div>
                    </validation-provider>
                  </th>
                </tr>
                <tr class="header-third-row">
                  <th class="p-75">
                    Name
                  </th>
                  <th>Sum of Allocation(%)</th>
                  <th>Billable Hours</th>
                  <th>Non-Billable Hours</th>
                  <th>Total Planned Hours</th>
                  <th>Actual Hours</th>
                  <th>Productive Hours</th>
                </tr>
              </thead>
              <tbody v-if="report.project_health_report_users && report.project_health_report_users.length">
                <tr
                  v-for="(reportUser, key) in report.project_health_report_users"
                  :key="key"
                  class="data-row"
                >
                  <td class="p-75">
                    {{ reportUser && reportUser.user && reportUser.user.full_name ? reportUser.user.full_name : 'N/A' }}
                  </td>
                  <td>{{ reportUser && reportUser.allocation }}%</td>
                  <td>{{ reportUser && reportUser.billable_hours }}</td>
                  <td>{{ reportUser && reportUser.non_billable_hours }}</td>
                  <td>{{ reportUser && reportUser.total_planned_hours }}</td>
                  <td :class="setActualColumnFontClass(reportUser.total_planned_hours,reportUser.actual_hours)">
                    {{ reportUser && reportUser.actual_hours }}
                  </td>
                  <td>{{ reportUser && reportUser.productive_hours }}</td>
                </tr>
                <tr class="data-row total-tr">
                  <td class="p-50">
                    Total
                  </td>
                  <td class="">
                    {{ getTotalAllocation(report.project_health_report_users) }}%
                  </td>
                  <td class="">
                    {{ getTotalBillableHours(report.project_health_report_users) }}
                  </td>
                  <td class="">
                    {{ getTotalNonBillableHours(report.project_health_report_users) }}
                  </td>
                  <td class="">
                    {{ getTotalPlannedHours(report.project_health_report_users) }}
                  </td>
                  <td class="">
                    {{ getTotalActualHours(report.project_health_report_users) }}
                  </td>
                  <td class="">
                    {{ getTotalProductiveHours(report.project_health_report_users) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </validation-observer>
        </b-row>
      </div>

      <!-- no data found image -->
      <div
        v-else
        class="project-health-report-list w-100"
      >
        <img
          src="@/assets/images/nodatafound/nodata.svg"
          alt="no-data"
          class="no-data-img"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import ProgressShimmer from '../../../views/shimmer/ProgressShimmer'

export default {
  name: 'HeaderBlock',
  components: {
    ValidationObserver,
    ValidationProvider,
    customDropdown,
    apexchart: VueApexCharts,
    ProgressShimmer,
  },
  data() {
    return {
      project_id: null,
      reportLoader: true,
      projectLists: [],
      reportList: [],
      tab: this.$route.query.tab ?? 'listing',

      // series
      series: [
        {
          name: 'Project Health',
          data: [],
        },
      ],

      // chart data
      chartOptions: {
        chart: {
          id: 'health-chart',
          toolbar: { show: false, tools: { download: false } },
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          categories: [], // Initialize with API data
        },
        yaxis: {
          tickAmount: 2,
          min: 1,
          max: 3,
          labels: {
            formatter(value) {
              const labels = ['Bad', 'Normal', 'Good']
              if (value >= 1 && value <= 3) {
                return labels[Math.round(value) - 1]
              }
              return ''
            },
          },
        },

        stroke: {
          curve: 'smooth',
        },
        markers: {
          size: 5,
        },
        tooltip: {
          shared: true,
          intersect: false,
          followCursor: true,
          theme: 'light',
          x: {
            show: true,
          },

          marker: {
            show: true,
          },
        },
      },

    }
  },
  computed: {
    projectName() {
    // Find the project in the list that matches the given project_id
      const project = this.projectLists.find(proj => proj.value === this.project_id)

      // Return the project name or a default label if not found
      return project ? project.label : ''
    },
  },
  watch: {
    project_id() {
      this.callMethod()
    },
    tab() {
      this.callMethod()
    },
  },
  mounted() {
    this.projectList()
  },

  methods: {
    callMethod() {
      const { query } = this.$route

      this.$router.push({
        query: {
          ...query,
          project_id: this.project_id,
          tab: this.tab,
        },
      })
      if (this.tab == 'chart') {
        this.series = []
        this.getProjectHealthChartData()
      } else {
        this.reportList = []
        this.getProjectHealthReports()
      }
    },
    async projectList() {
      let input = {}
      let response
      if (this.userInfo && this.userInfo.role === 'SA') {
        response = await this.getHTTPPostResponse(
          'project/log-project-list',
          input,
          false,
        )
      } else {
        input = {
          type: 'projectDetail',
          is_active: true,
        }
        response = await this.getHTTPPostResponse(
          'project/time-log',
          input,
          false,
        )
      }
      if (response && response.data) {
        const { data } = response
        this.projectLists = data.projects
          .filter(e => e.work_type !== 'non_productive')
          .map(e => ({
            ...e,
            label: e.name,
            value: e.id,
          }))

        const queryParams = this.$route.query
        // Check if a specific query parameter exists
        if (this.projectLists && this.projectLists.length) {
          this.project_id = queryParams.project_id ? queryParams.project_id : this.projectLists[0].id
        }
      }
    },

    handleSubmit(index, validate) {
      validate().then(() => {
        if (this.reportList[index].project_health && this.reportList[index].client_satisfaction) {
          this.changeStatus(index)
        }
      })
    },
    async changeStatus(index) {
      const title = 'Are you sure to save & freeze data?'
      const confirmation = await this.conformationAlert(false, title, 'Yes')

      if (confirmation.isConfirmed) {
        this.saveReport(index)
      } else {
        this.callMethod()
      }
    },
    toggleEdit(index) {
      this.$set(this.reportList[index], 'isEditing', !this.reportList[index].isEditing)
    },
    async saveReport(index) {
      // Logic to save the report data
      this.reportUpdateLoader = true
      const reportData = this.reportList[index]
      const input = {
        project_health: reportData.project_health,
        client_satisfaction: reportData.client_satisfaction,
      }
      const response = await this.getHTTPPostResponse(
        `project-health-report/update/${reportData.id}`,
        input,
        false,
      )
      if (response && response.data) {
        this.reportList[index].is_reviewed = true
        const ratingObj = this.projectHealthRating.find(rat => rat.value == this.reportList[index].project_health)
        if (ratingObj) {
          this.reportList[index].project_health_label = ratingObj.name
        }
        this.toggleEdit(index)
      }
      this.reportUpdateLoader = false
    },
    async getProjectHealthReports() {
      this.reportLoader = true
      const input = {
        project_id: this.project_id,
        months: [],
      }
      const response = await this.getHTTPPostResponse(
        'project-health-report',
        input,
        false,
      )
      if (response && response.data) {
        this.reportList = response.data.reports.map(report => ({
          ...report, // Copy the existing report data
          project_health_label: report.project_health
            ? this.projectHealthRating.find(rat => rat.value == report.project_health)?.name || report.project_health
            : report.project_health, // Set project_health to the found name or retain the original value if not found
          isEditing: false, // Add a new property to indicate edit state
        }))
      }
      this.reportLoader = false
    },

    async getProjectHealthChartData() {
      this.reportLoader = true
      const input = {
        project_id: this.project_id,
      }
      const response = await this.getHTTPPostResponse(
        'project-health-report/chart-data',
        input,
        false,
      )

      if (response && response.data) {
        // Ensure project_health is an array of numbers and categories are updated
        this.series = [
          {
            name: 'Project Health',
            data: response.data.project_health, // Make sure these are numbers 1-5
          },
        ]
        this.chartOptions.xaxis.categories = response.data.months

        // Update the chart with the new series and options
        setTimeout(() => {
          this.$refs.chart.updateSeries(this.series)
          this.$refs.chart.updateOptions({
            xaxis: {
              categories: this.chartOptions.xaxis.categories,
            },
          })
        }, 100)
      }

      this.reportLoader = false
    },

    reportMonthFormat(date) {
      const formattedDate = moment(date, 'M-YYYY').format('MMMM-YY')
      return formattedDate
    },

    // Methods to calculate totals
    getTotalAllocation(reportUsers) {
      return reportUsers.reduce((total, user) => total + parseFloat(user.allocation || 0), 0).toFixed(2)
    },
    getTotalBillableHours(reportUsers) {
      return reportUsers.reduce((total, user) => total + parseFloat(user.billable_hours || 0), 0).toFixed(2)
    },
    getTotalNonBillableHours(reportUsers) {
      return reportUsers.reduce((total, user) => total + parseFloat(user.non_billable_hours || 0), 0).toFixed(2)
    },
    getTotalPlannedHours(reportUsers) {
      return reportUsers.reduce((total, user) => total + parseFloat(user.total_planned_hours || 0), 0).toFixed(2)
    },
    getTotalActualHours(reportUsers) {
      return reportUsers.reduce((total, user) => total + parseFloat(user.actual_hours || 0), 0).toFixed(2)
    },
    getTotalProductiveHours(reportUsers) {
      return reportUsers.reduce((total, user) => total + parseFloat(user.productive_hours || 0), 0).toFixed(2)
    },

    setActualColumnFontClass(totalHours, actualHours) {
      if (totalHours == 0) {
        return 'text-warning'
      }

      const per = actualHours / totalHours * 100

      // Return the appropriate class based on the percentage
      return per > 120 ? 'text-danger' : per > 80 ? 'text-success' : 'text-warning'
    },
    back() {
      const { query } = this.$route

      this.$router.push({
        name: 'projectReports',
        query: {
          ...query,
        },
      })
    },

  },
}
</script>

<style lang="scss">
@import "../../../assets/scss/variables/_variables.scss";

.report-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.header-first-row {
  background-color: #283046;
  color: #ffff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}

.header-first-row-button {
  background-color: $primary-color;
  color: $white;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.header-second-row th {
  text-align: center;
  font-size: 14px;
  font-weight:700;
  border-bottom: 1px solid $table-border;
}

.header-second-row th:first-child {
  border-left: 1px solid $table-border;
}

.header-second-row th:last-child {
  border-right: 1px solid $table-border;
}

.header-third-row th {
  border: 1px solid $table-border;
  padding: 0.5rem;
}

.data-row {
  text-align: center;
  background-color: $white;
}

.data-row:hover {
  background-color: #283046c9;
  color: $white !important;
}

.data-row td {
  border: 1px solid $table-border;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-edit {
  background-color: $dark-gray;
}

.input-display {
  background-color: transparent;
}

.project-health-report-list {
  width: 100%;
  max-height: calc(100vh - 200px) !important;
  overflow: auto;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      background-color: transparent;
      border-radius: 10px;
    }
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
    background-color: $scroller-color;
  }

}

.btn-save,
.btn-edit {
  color: $white;
  border: none;
}

.project-health-report-list {
  width: 100%;
  max-height: 675px;
  overflow: auto;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

.header-second-row,
.header-third-row {
  width: 100%;
}

.header-third-row {
  text-align: center;
}

.data-row {
  width: 100%;
  font-size: 12px;
  font-weight: 700;
}

td,
th {
  box-sizing: border-box;
}

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-2px);
  }

  50% {
    transform: translateX(2px);
  }

  75% {
    transform: translateX(-2px);
  }

  100% {
    transform: translateX(0);
  }
}

.vibrate-on-error {
  animation: vibrate 0.5s ease-in-out;
}

.is_reviewed {
  background-color: #6BBE74;
  cursor: default;
}

.card-header-second {
  margin-top: 33px;
  display: flex;
  flex-wrap: nowrap;
  align-items: start;

  @media (max-width: map-get($mediaSizes , "md")) {
    flex-wrap: wrap !important;
    width: 100%;
  }

  .btn-group {
    width: 240px;
    margin-right: 10px;
  }

  .button-group-item {
    background-color: rgba(35, 184, 114, 0.09) !important;
    color: $dark-gray !important;
    border-color: transparent !important;
    font-weight: $fw600;
    height: 40px;
    border: 0 !important;
    padding: 8px 42px;

    @media (max-width: map-get($mediaSizes , "md")) {
      margin-bottom: 10px;
      height: 34px;
    }

    @media all and (max-width: 1250px) {
      padding: 8px 30px;
    }
  }

  .button-group-item-weekly {
    background-color: rgba(35, 184, 114, 0.09) !important;
    color: $dark-gray !important;
    border-color: transparent !important;
    font-weight: $fw600;
    height: 40px;
    border: 0 !important;
    padding: 8px 34px;

    @media (max-width: map-get($mediaSizes , "md")) {
      margin-bottom: 10px;
      height: 34px;
    }

    @media all and (max-width: 1250px) {
      padding: 8px 30px;
    }
  }

  .active {
    background-color: $green !important;
    color: $white !important;
    border-radius: 5px !important;
  }

  .card-header-item {
    display: flex;
    align-content: center;

    @media (max-width: map-get($mediaSizes , "xs")) {
      flex-wrap: wrap;
    }

    .btn-fillter {
      margin-left: 20px;
      background-color: $secondary-color !important;
      border-color: $secondary-color !important;
      height: 40px;
      padding: 9px 25px;

      @media (max-width: map-get($mediaSizes , "xs")) {
        margin-left: 0;
      }
    }

    .select-group {
      width: 193px;
      font-weight: $fw600;
      font-size: 1.75 * $rem;

      @media (max-width: map-get($mediaSizes , "xxs")) {
        width: 200px;
      }
    }
  }

  .vs__dropdown-toggle {
    height: 40px !important;
    border: 1px solid rgba(33, 120, 251, 0.5) !important;

    &::placeholder {
      font-weight: $fw600 !important;
      font-size: 1.75 * $rem !important;
    }
  }
}

.pending-to-review {
  background-color: #F67652;
}

.btn .btn-edit .header-first-row-button .pending-to-review {
  &:hover {
    color: $white !important;
  }
}

.total-tr{
  font-size: 12px;
  font-weight: 700;
  line-height: 1.45;
  color: #6e6b7b;
  &:hover{
    color:$white !important;
  }
}

.header-project-name{
  font-size: 20px;
  font-weight: 700;
  line-height: 1.45;
  color: #283046;
}

</style>
